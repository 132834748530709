const isBrowser = typeof window !== 'undefined'

export const getStore = (key) => {
  // eslint-disable-next-line
  if (!isBrowser || !window?.localStorage)
    return console.error('No access to local storage')

  // eslint-disable-next-line
  return JSON.parse(window.localStorage.getItem(key))
}

export const store = (key, data) => {
  // eslint-disable-next-line
  if (!isBrowser || !window.localStorage)
    return console.error('No access to local storage')
  // eslint-disable-next-line
  return window.localStorage.setItem(key, JSON.stringify(data))
}
