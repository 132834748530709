import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import PropTypes from 'prop-types'

import { store } from 'lib/storage/local-storage'

const Context = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SAVED_JOBS':
      return { ...state, savedJobs: action.payload }
    default:
      return state
  }
}

const initialState = { savedJobs: {} }

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setSavedJobs = useCallback(
    (payload) => {
      dispatch({ type: 'SET_SAVED_JOBS', payload })
      store('ppSavedJobs', payload)
    },
    [dispatch]
  )

  const value = {
    ...state,
    setSavedJobs,
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const useGlobalContext = () => useContext(Context)

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

const GlobalProvider = ({ element }) => <Provider>{element}</Provider>

GlobalProvider.propTypes = {
  element: PropTypes.node.isRequired,
}

export { GlobalProvider, useGlobalContext }
