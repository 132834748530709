import { GlobalProvider } from './src/context'
import { trackPageChange } from './src/lib/tracking'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = GlobalProvider

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // eslint-disable-next-line
  if (!('IntersectionObserver' in window)) {
    // eslint-disable-next-line
    import('intersection-observer')
  }
}

export const onRouteUpdate = ({ location }) => {
  trackPageChange(location.pathname)
}
