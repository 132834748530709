module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.pixel-pond.com","exclude":["/successful-application","/saved","/jobs/*/apply"],"noHash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pixel Pond","start_url":"/","background_color":"#32DAC3","theme_color":"#32DAC3","display":"minimal-ui","icon":"src/images/pixel-pond-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a4245a1da4500d27610aaa4f1481930a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://3bc299069c25467ab62a02833a5f29e1@o423449.ingest.sentry.io/5353705","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
